import React from "react"
import Arrow from "./arrow"
import MailTo from "./mail-to"

import levelIntroductionData from "../data/level-introduction";

const LevelIntroduction = ({children}) => (
    <>
        <h1 className={"title"}>
            {levelIntroductionData.title.map((lineText,i)=>(
                <span key={i} className="over">{lineText} </span>
            ))}
        </h1>
        <hr className={'rule'}/>
        <span><MailTo className={"over"} /></span>
        <Arrow to="precisions" direction="down" />

    </>
)

export default LevelIntroduction
