import React from "react"
import Arrow from "./arrow"
import levelPrecisionsData from "../data/level-precisions";

const LevelPrecisions = () => (
    <>
        <div className="container">
            <p className={'col'}>
                <span className="over">
                    {levelPrecisionsData.paragraph1}
                </span>
            </p>
            <p className={'col'}>
                <span className="over">
                    {levelPrecisionsData.paragraph2}
                </span>
            </p>
        </div>

        <Arrow to="top" direction="up" />
        <Arrow to="contact" direction="down" />
    </>
)

export default LevelPrecisions
