import React from "react"
import animateScrollTo from 'animated-scroll-to'

class Arrow extends React.Component{

    navigate(event){
        switch(this.props.to){
            case 'top':
                animateScrollTo(0);
                break;
            default:
                animateScrollTo(document.getElementById(this.props.to));
        }
    }

    render(){
        return (
        <button aria-label={`${this.props.direction} ${this.props.to}`} type={'button'} onTouchStart={this.navigate.bind(this)} onClick={this.navigate.bind(this)} className={`arrow arrow-${this.props.direction}`} />
        );
    }
}

export default Arrow;

