import React from "react"

import Layout from "../components/layout"
import Level from "../components/level"
import LevelIntroduction from "../components/level-introduction"
import LevelPrecisions from "../components/level-precisions"
import LevelContact from "../components/level-contact"
import SEO from "../components/seo"

import Overlay from "../components/overlay-image";

class IndexPage extends React.Component {

    render () {return (
      <Layout>
        <SEO title="Samuel Rambaud" keywords={[`Samuel Rambaud`, `design editorial`, `scénographie`, `design graphique`, `signalétique`]} />
          <Level onClick={this.handleScroll} levelId="introduction">
              <LevelIntroduction />
          </Level>
          <Level transparent={true} />
          <Level levelId="precisions">
              <LevelPrecisions />
          </Level>
          <Level transparent={true} />
          <Level levelId="contact">
              <LevelContact />
          </Level>
          <Overlay />
      </Layout>
    )}
}

export default IndexPage