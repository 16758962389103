import React from "react"
import Arrow from "./arrow"
import MailTo from "./mail-to"
import levelContactData from "../data/level-contact";


const LevelContact = () => (
    <>
        <Arrow to="top" direction="up" />
        <div className="title address">
            {levelContactData.address.split('\n').map((lineText,i)=>(
                <span key={i} className="over">{lineText} </span>
            ))}
            <span className="over"><MailTo /> </span>
        </div>
        <footer id={`footer`}>
            <ul>
                {levelContactData.credits.split('\n').map((lineText,i)=>(
                <li key={i} dangerouslySetInnerHTML={{__html:lineText}} />
                ))}
            </ul>
        </footer>
    </>
)

export default LevelContact
