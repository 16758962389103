import React from "react"

import illustrations from "../data/illustrations";

import overlay1 from "../../static/images/overlay-1.svg";
import overlay2 from "../../static/images/overlay-2.svg";
import overlay3 from "../../static/images/overlay-3.svg";

const //steps = [0,.1,.45,.55,.9,1],
    overlays =[illustrations.overlay1,illustrations.overlay2,illustrations.overlay3];

class OverlayImage extends React.Component{

    checkbox;
    img;
    overlayRef;
    imageLoaded = false;
    canvasReady = false;
    drawing = false;
    rendering = false;
    index = 1;
    footerSize = 0;

    constructor(props){
        super(props);

        let self = this;
        self.overlayRef = React.createRef();

        self.handleScrollBinding = self.handleScroll.bind(self);
        self.handleResizeBinding = self.handleResize.bind(self);

    }

    loadImage(id,setCurrent){
        const self = this, img = self.refs[`img${id}`];
        if(img) {
            if(setCurrent){
                self.img = img;
            }
            img.src = overlays[id-1];
            self.onLoadImageBinding = self.onLoadImage.bind(self);
            img.addEventListener('load', self.onLoadImageBinding);
            self.drawBinding = self.draw.bind(self);
        }
    }

    onLoadImage(event){
        let self = this;
        self.imageLoaded = true;
        event.target.removeEventListener('load',self.onLoadImageBinding);
    }

    componentDidMount(){

        let self = this;

        self.handleScrollBinding = self.handleScroll.bind(self);
        self.handleResizeBinding = self.handleResize.bind(self);

        window.addEventListener('scroll',self.handleScrollBinding);
        window.addEventListener('resize',self.handleResizeBinding);


        self.loadImage(1,true);
        self.loadImage(2,false);
        self.loadImage(3,false);

        this.handleResize();
    }
    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScrollBinding);
        window.removeEventListener('resize',this.handleResizeBinding);
    }

    toggleDrawing(){
        this.drawing = !this.drawing;
        if(!this.drawing){
            this.index++;
            if(this.index>3){
                this.index=1;
            }
            this.img = this.refs[`img${this.index}`];
        }
        document.getElementById('figure').setAttribute('data-fig', this.drawing?this.index:'none')
        this.draw();
    }

    draw(){
        let self = this,
            img = self.img,
            DPR = this.DPR,
            w = this.width,
            h = this.height,

            ctx = this.ctx;
        if(ctx && this.imageLoaded){
            let imgW = img.naturalWidth,
                imgH = img.naturalHeight,
                imgCropH = imgW * h /w,
                imgOffsetVerticalDiff = imgH - imgCropH + this.footerSize;
            ;

            let currentState = document.body.scrollTop || document.documentElement.scrollTop;
            let pageHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            let progress = (currentState / pageHeight);

            //var levels = document.getElementsByClassName('level');
            ctx.clearRect(0,0,w,h);
            ctx.resetTransform();

            if(this.drawing) {

                ctx.drawImage(this.img, 0, (progress) * imgOffsetVerticalDiff, imgW, imgCropH, 0, 0, w, h)
                ctx.scale(DPR, DPR);

            }
        }
        this.rendering = false;
    }

    handleScroll(){
        if(!this.rendering){
            this.rendering = true;
            requestAnimationFrame(this.drawBinding);
        }

    }

    renderPattern(){
        this.draw();
    }

    handleResize(){
        const canvas = this.ctx.canvas,
            footer = document.getElementById('footer'),
            DPR = window.devicePixelRatio || 1,
            w = window.innerWidth*DPR,
            h = window.innerHeight*DPR;

        this.footerSize = window.innerHeight-footer.offsetTop;

        this.canvasReady = true;
        this.width = canvas.width = w;
        this.height = canvas.height = h;
        this.DPR = DPR;
        if(this.imageLoaded){
            this.renderPattern();
        }
    }

    render(){
        return(
            <>
                <canvas id="overlay" className="overlay" ref={(c) => this.ctx = c ? c.getContext('2d') : null}  />
                <img src={illustrations.overlay1} className={'overlay-img'} id="img-overlay1" ref="img1" alt="" />
                <img src={illustrations.overlay2} className={'overlay-img'} id="img-overlay2" ref="img2" alt="" />
                <img src={illustrations.overlay3} className={'overlay-img'} id="img-overlay3" ref="img3" alt="" />
                <label className={'toggle-drawing'} type={'button'}>dessin <input onChange={this.toggleDrawing.bind(this)} ref="checkbox" type="checkbox" /><span></span></label>
            </>
        )
    }
}

export default OverlayImage;

